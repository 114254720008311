import React from 'react';

import {
    InfoContainer,
    InfoImage,
    TextInfo,
    InfoLink,
    ExitIcon,
    Title,
    TextContainer
}   from './floating-info.styles';

const FloatingInfoComponent = ({image, text, handleClick, title, handleLinkClick}) => {
    
    return (        
        <InfoContainer  >
            <ExitIcon onClick={handleClick} />       
            <InfoImage fluid = {image} />
            <TextContainer>
                <Title>{title}
                    {/* <InfoLink onClick={handleLinkClick}/> */}
                </Title>
                <TextInfo>{text}</TextInfo>
            </TextContainer>
        </InfoContainer>
    )
}




export default FloatingInfoComponent;